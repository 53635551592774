<template>
  <div class="home">
    <Index v-show="active == 0" />
    <SearchHistory v-show="active == 1" />
    <Cart v-show="active == 2" />
    <Profile v-show="active == 3" />
    <div class="bottom-white van-hairline--top"></div>
    <van-tabbar
      v-model="active"
      @change="tabChange"
      active-color="#FDB02A"
      inactive-color="#949494"
    >
      <van-tabbar-item icon="home-o">{{ $t("HOME") }}</van-tabbar-item>
      <van-tabbar-item
        icon="underway-o"
        style="font-size: 8px; text-align: center"
        >{{ $t("SEARCH HISTORY") }}</van-tabbar-item
      >
      <van-tabbar-item icon="shopping-cart-o">{{ $t("CART") }}</van-tabbar-item>
      <van-tabbar-item icon="contact">{{ $t("PROFILE") }}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import Index from "./index/Index.vue";
import Cart from "../cart/Index.vue";
import Profile from "../profile/Index.vue";
import SearchHistory from "../search-history/Index.vue";

import { Tabbar, TabbarItem } from "vant";

import { initCartDB } from "@/utils/cart.js";

export default {
  name: "Home",
  components: {
    Index,
    Cart,
    Profile,
    SearchHistory,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
  activated() {
    const { homeIndex } = this.$store.state;
    this.active = homeIndex;
  },
  deactivated() {
    this.$store.commit("setHomeIndex", this.active);
  },
  mounted() {
    const page = this.$route.query.tabName;

    if (page) {
      let homeIndex = 0;
      switch (page) {
        case "home":
          homeIndex = 0;
          break;
        case "search-history":
          homeIndex = 1;
          break;
        case "cart":
          homeIndex = 2;
          break;
        case "profile":
          homeIndex = 3;
          break;
      }

      this.$store.commit("setHomeIndex", homeIndex);
    }

    initCartDB();
    this.tabChange();
  },
  methods: {
    tabChange() {
      document.body.scrollIntoView();
    },
  },
};
</script>
<style lang="less" scoped>
.bottom-white {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
}

:deep(.van-tabbar--fixed) {
  max-width: 500px;
  margin: 0 auto;
  right: 0;
}

:deep(.bottom-white) {
  max-width: 500px;
  margin: 0 auto;
}
</style>
