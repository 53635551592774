<template>
  <div>
    <div class="search-top">
      <div class="search" @click="toSearch()">
        <van-icon name="search" />
        <div class="s-search">{{ $t("输入关键字搜索") }}</div>
      </div>
    </div>

    <div class="search-content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-top: 10px" :pulling-text="$t('下拉即可刷新...')"
        :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
        <van-swipe :autoplay="3000" lazy-render v-if="banners && banners.length != 0"
          style="height: 200px; margin: 0px 15px 10px">
          <van-swipe-item v-for="banner in banners" :key="banner.value">
            <van-image width="100%" height="100%" :src="banner.image" fit="cover" @click="bannerClick(banner)" />
          </van-swipe-item>
        </van-swipe>
        <van-list v-model="loading" :finished="finished" :immediate-check="false" style="
                margin-bottom: 50px;height:100%'
            " :finished-text="products.length ? $t('没有更多了') : ''" @load="onLoad">
          <van-grid :column-num="2" :gutter="10">
            <van-grid-item v-for="{ node: product } in products" :key="product.id" @click="toProduct(product.id)">
              <van-image width="100%" height="150px" :src="product.featuredImage && product.featuredImage.url"
                fit="cover" />
              <div class="product-name">
                {{
                  product.local_title && product.local_title.value
                  ? product.local_title.value
                  : product.title
                }}
              </div>
              <div class="product-price">
                <span style="flex: 1; font-size: 1rem" class="baht-price">฿{{ getPrice(product) }}</span>
                <span>{{
                  product.compare_at_platform_price
                  ? "Shopee:฿" + product.compare_at_platform_price.value
                  : " "
                }}</span>
              </div>
              <div class="product-price">
                <span style="flex: 1; font-size: 1rem"> </span>
                <span>
                  {{
                    product.compare_product_sold
                    ? product.compare_product_sold.value + "sold"
                    : " "
                  }}
                </span>
              </div>
            </van-grid-item>
          </van-grid>
        </van-list>
      </van-pull-refresh>
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
import {
  Field,
  Icon,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Grid,
  GridItem,
  PullRefresh,
  List,
  Toast,
} from "vant";
import { getCollections, getHomeBanner } from "./service";
import qs from "qs";
import { formatPrice } from "@/utils/utils";
import {
  errorMessage
} from "@/utils/apollo-utils"

export default {
  components: {
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Toast.name]: Toast,
  },
  data() {
    return {
      products: [],
      banners: [],
      refreshing: false,
      pageSize: 10,
      pageInfo: null,
      finished: true,
      loading: false,
    };
  },
  methods: {
    onRefresh() {
      this.pageInfo = null;
      this.refreshing = true;
      this.getData();
    },
    onLoad() {
      this.loading = true;
      this.getData();
    },
    toSearch() {
      this.$router.push({
        name: "search",
      });
    },
    bannerClick(item) {
      switch (item.type) {
        case "product":
          this.toProduct(item.value);
          break;
      }
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    getPrice(product) {
      const { priceRange, vendor } = product;
      if (!priceRange) return 0;
      const minPrice = priceRange.minVariantPrice.amount;
      return formatPrice(minPrice / 100);
    },
    isAli1688Product(vendor) {
      return "ali1688" === vendor;
    },
    async getData() {
      const after =
        this.pageInfo && this.pageInfo.endCursor
          ? `\\"${this.pageInfo.endCursor}\\"`
          : null;

      const returnsQlStr = `{
              edges {
                node {
                  id
                  title
                  products(first: ${this.pageSize},after:${after}) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      startCursor
                      endCursor
                    }
                    edges {
                      node {
                        id
                        title
                        vendor
                        featuredImage {
                          url
                        }
                        priceRange {
                          minVariantPrice {
                            amount
                          }
                        }
                        local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                          value
                        }
                        compare_at_platform_price:metafield(namespace:\\"products\\",key:\\"compare_at_platform_price\\"){
                          value
                        }
                        compare_product_sold:metafield(namespace:\\"products\\",key:\\"compare_product_sold\\"){
                          value
                        }
                      }
                    }
                  }
                }
              }
            }`;
      const payload = {
        first: 1,
        query: "title:Home Page",
        returnsQlStr,
      };

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { data: collect, success } = await getCollections(
        qs.stringify(payload)
      );
      Toast.clear();
      this.refreshing = false;
      this.loading = false;

      const { collections: data } = collect;
      if (data) {
        const { edges } = data;
        if (edges && edges.length > 0) {
          const { node } = edges[0];
          const { edges: pedges, pageInfo } = node.products;
          if (!pageInfo.hasPreviousPage) this.products = [];

          this.products = this.products.concat(pedges);
          this.pageInfo = pageInfo;
          this.finished = !pageInfo.hasNextPage;
        }
      }
    },
    async getHomeBanner() {
      const { data, errors } = await getHomeBanner();
      if (errors) {
        // const errMsg = errorMessage(errors)
        // Toast.fail(errMsg)
        return
      }
      const { edges } = data.pages;
      if (edges && edges.length > 0) {
        const { node } = edges[0];
        const { edges: pedges } = node.metafields;
        const banner = pedges.find((metafield) => {
          const { key } = metafield.node;
          return key === "banner";
        });
        const bstrs = banner.node.value;
        const banners = JSON.parse(bstrs);
        this.banners = banners;
      }
    },
  },
  mounted() {
    this.getData();
    this.getHomeBanner();
  },
};
</script>

<style lang="less" scoped>
.search-top {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 9;
  max-width: 500px;
  padding: 15px;
  margin: 0 auto;
}

.search {
  background-color: white;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
}

.s-search {
  display: inline-block;
  padding: 0px 10px;
  color: #666;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-content {
  padding-top: 70px;
}

.product-name {
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  margin: 5px 0px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

:deep(.van-grid-item__content--center) {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: start !important;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.product-price {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;
  min-height: 0.8rem;
}

.baht-price {
  color: red;
}
</style>