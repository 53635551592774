<template>
  <div>
    <div>
      <div class="profile-top" @click="checkLogin()">
        <van-image
          round
          width="60"
          height="60"
          :src="userInfo.user ? userInfo.user.avatar : ''"
        />
        <div v-if="!isLogin" class="profile-nickname">
          {{ $t("前往登录") }}
        </div>
        <div v-if="isLogin && userInfo.user" class="profile-nickname">
          {{ userInfo.user.nickName }}
          <div v-if="false">vip1 view points</div>
        </div>
      </div>

      <div class="order-item">
        <van-cell
          :title="$t('My Orders')"
          is-link
          :value="$t('All orders')"
          @click="toOrders(1)"
        />
        <van-row type="flex" justify="space-around" style="padding: 10px 0px">
          <van-col span="4" @click="toOrders(1)">
            <div class="bill-status">
              <van-icon class="bill-icon" name="sign" size="30" />
              {{ $t("To be confirmed") }}
            </div>
          </van-col>
          <van-col span="4" @click="toOrders(2)">
            <div class="bill-status">
              <van-icon class="bill-icon" name="balance-pay" size="30" />
              {{ $t("To Pay") }}
            </div>
          </van-col>
          <van-col span="4" @click="toOrders(3)">
            <div class="bill-status">
              <van-icon class="bill-icon" name="send-gift-o" size="30" />
              {{ $t("To Ship") }}
            </div>
          </van-col>
          <van-col span="4" @click="toOrders(4)">
            <div class="bill-status">
              <van-icon class="bill-icon" name="logistics" size="30" />
              {{ $t("To Receive") }}
            </div>
          </van-col>
          <van-col span="4" @click="toOrders(5)">
            <div class="bill-status">
              <van-icon class="bill-icon" name="after-sale" size="30" />
              {{ $t("Cancelled") }}
            </div>
          </van-col>
        </van-row>
      </div>

      <van-cell v-if="false" :title="$t('充值')" is-link />
      <van-cell :title="$t('Addresses')" is-link @click="toAddresses()" />
      <van-cell
        :title="$t('语言切换')"
        :value="getCurrentLanguage()"
        is-link
        @click="showLanguages()"
      />
      <van-cell v-if="false" title="Coupons" is-link :to="{ name: 'coupon' }" />
      <van-cell
        :title="$t('Help center')"
        is-link
        :to="{ name: 'helpcenter' }"
      />
      <van-cell
        :title="$t('运费计算器')"
        is-link
        :to="{ name: 'calculator' }"
      />
      <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    </div>

    <div v-if="isLogin" class="logout" @click="showLogoutDialog">{{ $t("退出登录") }}</div>

    <van-dialog
      v-model="cancelShow"
      title=""
      show-cancel-button
      :cancelButtonText="$t('取消')"
      :confirmButtonText="$t('确定')"
      @confirm="onLogoutConfirm"
    >
      <div style="padding: 20px 15px">
        <div style="text-align: center">{{ $t("退出登录") }}?</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Image, Icon, Cell, Col, Row, Toast, ActionSheet, Dialog } from "vant";
import { fetchUserInfo } from "./service.js";
import { setLocales } from "@/utils/lang";
import { isLogin } from "@/utils/token";
import { toAuthorizationLogin } from "@/utils/pages";
import { getLocale } from "@/utils/storage";
import { clearAccessToken } from "@/utils/token";

export default {
  components: {
    [Image.name]: Image,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [ActionSheet.name]: ActionSheet,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      show: false,
      actions: [
        { name: "English", value: "en-US" },
        { name: "ภาษาไทย", value: "th-TH" },
        { name: "中文繁體", value: "zh-TW" },
      ],
      userInfo: {},
      cancelShow: false,
      isLogin: isLogin(),
    };
  },
  methods: {
    //弹出确认退出登录框
    showLogoutDialog() {
      this.cancelShow = true;
    },
    onLogoutConfirm() {
      clearAccessToken();
      this.userInfo = {};
      this.isLogin = false;
    },
    toOrders(orderType) {
      if (this.checkLogin()) {
        this.$store.commit("setOrderIndex", -1);
        this.$router.push({
          name: "orders",
          query: {
            orderType,
          },
        });
      }
    },
    toAddresses() {
      if (this.checkLogin())
        this.$router.push({
          name: "addresses",
          query: {
            origin: "manage",
          },
        });
    },
    checkLogin() {
      if (!this.isLogin) {
        Toast(this.$t("Please login"));
        setTimeout(() => {
          toAuthorizationLogin({
            name: "Home",
            query: {
              tabName: "profile",
            },
          });
        }, 500);
      }
      return true;
    },
    onSelect(item) {
      this.show = false;
      setTimeout(() => {
        this.changeLanguage(item.value);
      }, 500);
    },
    showLanguages() {
      this.show = true;
    },
    async fetchUserInfo() {
      const res = await fetchUserInfo();
      const { success, data } = res;
      if (success) {
        this.userInfo = data;
      }
    },
    changeLanguage(value) {
      setLocales(value);
    },
    getCurrentLanguage() {
      const locale = getLocale();
      switch (locale) {
        case "zh-TW":
          return "中文繁體";
        case "th-TH":
          return "ภาษาไทย";
        default:
          return "English";
      }
    },
  },
  mounted() {
    if (this.isLogin) this.fetchUserInfo();
  },
};
</script>

<style lang="less" scoped>
.profile-top {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px 15px;
}

.profile-nickname {
  flex: 1;
  padding: 0px 15px;
}

.order-item {
  background-color: white;
  margin: 15px 0px;
}

.bill-status {
  text-align: center;
  font-size: 0.8rem;
}

.bill-icon {
  display: block;
  margin-bottom: 5px;
}

.logout {
  background-color: white;
  padding: 15px;
  margin-top: 15px;
  font-size: 0.8rem;
  text-align: center;
}


:deep(.van-overlay) {
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
}

:deep(.van-popup) {
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>