import {
    query
} from "@/utils/apollo-utils.js"
import {
    getHomeBanner as getHomeBannerGql
} from "./gqls.js"
import axios from '@/utils/request';

export async function getCollections(payload) {
    return axios.post('shopify/query/collections', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}

export async function getHomeBanner() {
    return query(getHomeBannerGql)
}