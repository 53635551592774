<template>
  <div style="padding-bottom: 95px; font-size: 0.9rem">
    <van-empty
      v-if="!sellers || sellers.length === 0"
      :description="$t('Cart is empty')"
      image-size="200px"
    />
    <div
      class="vendor-item"
      v-for="seller in sellers"
      :key="seller.seller_member_id"
    >
      <div class="vendor-title" @click="sellerChange(seller)">
        <van-icon
          v-if="!seller.checked"
          name="circle"
          size="16"
          color="#c8c9cc"
        />
        <van-icon
          v-if="seller.checked"
          name="checked"
          size="16"
          color="#EE502F"
        />
        <span style="display: inline-block; padding-left: 5px">{{
          seller.seller_member_id
        }}</span>
      </div>
      <div v-for="product in seller.products" :key="product.id">
        <div class="cart-item" @click="productChange(product)">
          <van-icon
            v-if="!product.checked"
            name="circle"
            size="16"
            color="#c8c9cc"
          />
          <van-icon
            v-if="product.checked"
            name="checked"
            size="16"
            color="#EE502F"
          />

          <van-image
            width="60"
            height="60"
            :src="product.image"
            style="margin: 0px 10px"
            radius="5"
            @click.stop="toProduct(product.id)"
          />

          <span class="product-title">
            {{ product.title }}
          </span>
        </div>
        <div
          v-for="variant in product.variants"
          :key="variant.variantId"
          style="padding: 15px 0px 0px"
        >
          <div class="variant-item" @click="variantChange(variant)">
            <van-icon
              v-if="!variant.checked"
              name="circle"
              size="16"
              color="#c8c9cc"
            />
            <van-icon
              v-if="variant.checked"
              name="checked"
              size="16"
              color="#EE502F"
            />

            <van-image
              width="25"
              height="25"
              :src="variant.image"
              radius="2"
              class="product-image"
            />

            <span class="product-title variant-sku">
              {{ formatThValue(variant.sku) }}
            </span>

            <van-icon
              name="delete-o"
              size="20"
              style="padding-left: 5px"
              @click.stop="removeCart(variant)"
            />
          </div>
          <div class="variant-item">
            <div>
              <span class="color-thb"
                >฿{{
                  variant.discountPrice ? variant.discountPrice : variant.price
                }}</span
              >
              <span v-if="variant.cny_price">
                ¥{{
                  variant.discountPriceRMB
                    ? variant.discountPriceRMB
                    : variant.cny_price
                }}</span
              >
            </div>
            <span
              @click.stop="showQuantityDialog(variant)"
              style="
                display: inline-block;
                flex: 1;
                text-align: right;
                padding: 10px 0px;
              "
            >
              x{{ variant.quantity }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="quantityShow"
      :title="$t('Quantity')"
      show-cancel-button
      @confirm="onQuantity"
    >
      <van-stepper
        v-model="quantity"
        :min="getMinStartAmount()"
        theme="round"
        input-width="50px"
        button-size="30px"
      />
    </van-dialog>

    <div
      class="pay-now mobile-fixed-theme"
      v-if="sellers && sellers.length !== 0"
    >
      <div @click="checkAll" style="padding-left: 15px">
        <van-icon v-if="!allChecked" name="circle" size="16" color="#c8c9cc" />
        <van-icon v-if="allChecked" name="checked" size="16" color="#EE502F" />

        <span style="display: inline-block; padding: 0px 5px">{{
          $t("全选")
        }}</span>
      </div>

      <div style="flex: 1; text-align: right; padding-right: 10px">
        <span> {{ $t("Total") }}: </span>
        <div style="display: inline-block">
          <span style="color: #ee502f">฿{{ totalPrice }}</span>
          <br />
        </div>
      </div>

      <van-button
        class="button-pay-now"
        @click="onSubmit"
        color="#EE502F"
        size="small"
        style="font-size: 0.9rem; margin-right: 15px"
        round
      >
        {{ $t("Submit") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  SubmitBar,
  Empty,
  Toast,
  Cell,
  Image,
  Icon,
  Dialog,
  Stepper,
  Checkbox,
  Button,
} from "vant";
import { queryCarts, removeCart, updateQuantity } from "@/utils/cart.js";
import { isLogin } from "@/utils/token";
import { toAuthorizationLogin } from "@/utils/pages";
import { formatThValue } from "@/utils/utils";

export default {
  components: {
    [Empty.name]: Empty,
    [SubmitBar.name]: SubmitBar,
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Checkbox.name]: Checkbox,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loading: false,
      allChecked: false,
      totalPrice: 0,
      quantityShow: false,
      quantity: 1,
      quantityVariant: null,
      sellers: [],
      store: this.$store.state,
    };
  },
  methods: {
    formatThValue(value) {
      return formatThValue(value);
    },
    checkAll() {
      this.allChecked = !this.allChecked;
      this.sellers.forEach((seller) => {
        seller.checked = this.allChecked;

        const { products } = seller;
        products.forEach((product) => {
          product.checked = this.allChecked;
          const { variants } = product;
          variants.forEach((variant) => {
            variant.checked = this.allChecked;
            this.onoVariantCheckedChange(variant.variantId, this.allChecked);
          });
        });
      });

      this.calculateTotalPrice();
      this.$forceUpdate();
    },
    onoVariantCheckedChange(variantId, checked) {
      this.$store.commit("onVariantCheck", [variantId, checked]);
    },
    isAllSellerChecked() {
      const allSellerChecked = this.sellers.every((s) => {
        return s.checked;
      });
      this.allChecked = allSellerChecked;

      this.calculateTotalPrice();
      this.$forceUpdate();
    },
    sellerChange(seller) {
      seller.checked = !seller.checked;
      const { seller_member_id, checked } = seller;

      const changeSeller = this.sellers.find((s) => {
        return s.seller_member_id === seller_member_id;
      });
      changeSeller.products.forEach((product) => {
        product.checked = checked;
        product.variants.forEach((variant) => {
          variant.checked = checked;
          this.onoVariantCheckedChange(variant.variantId, checked);
        });
      });
      this.isAllSellerChecked();
    },
    productChange(product) {
      product.checked = !product.checked;
      const { checked } = product;

      const seller = this.sellers.find((s) => {
        return s.seller_member_id === product.seller_member_id;
      });

      const allChecked = seller.products.every((p) => {
        return p.checked;
      });
      seller.checked = allChecked;

      product.variants.forEach((variant) => {
        variant.checked = checked;
        this.onoVariantCheckedChange(variant.variantId, checked);
      });
      this.isAllSellerChecked();
    },
    variantChange(variant) {
      variant.checked = !variant.checked;
      this.onoVariantCheckedChange(variant.variantId, variant.checked);
      const seller = this.sellers.find((s) => {
        return s.seller_member_id === variant.metafields.seller_member_id;
      });

      const { products } = seller;
      const product = products.find((p) => {
        return p.id === variant.productId;
      });

      const allVariantChecked = product.variants.every((v) => {
        return v.checked;
      });
      product.checked = allVariantChecked;

      const allProductChecked = seller.products.every((p) => {
        return p.checked;
      });
      seller.checked = allProductChecked;

      this.isAllSellerChecked();
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    getMinStartAmount() {
      if (!this.quantityVariant) return 1;
      const { price_ranges } = this.quantityVariant.metafields;
      if (!price_ranges) return 1;
      const { rmb } = JSON.parse(price_ranges);
      let priceRange = rmb.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });

      if (priceRange && priceRange.length > 0) {
        const { startAmount } = priceRange[0];
        return startAmount;
      }
      return 1;
    },
    onSubmit() {
      if (!isLogin()) {
        Toast(this.$t("Please login"));
        setTimeout(() => {
          toAuthorizationLogin({
            name: "Home",
            query: {
              tabName: "cart",
            },
          });
        }, 500);
        return;
      }

      let checkedSellers = [];
      this.sellers.forEach((seller) => {
        const { products, seller_member_id } = seller;

        let checkedProducts = [];
        products.forEach((product) => {
          const { id, title, image, variants } = product;

          const checkedVariants = variants.filter((variant) => {
            return variant.checked;
          });

          if (checkedVariants && checkedVariants.length !== 0) {
            const checkedProduct = {
              id,
              title,
              image,
              variants: checkedVariants,
            };
            checkedProducts.push(checkedProduct);
          }
        });

        if (checkedProducts.length !== 0) {
          const checkedSeller = {
            seller_member_id,
            products: checkedProducts,
          };
          checkedSellers.push(checkedSeller);
        }
      });

      if (checkedSellers.length === 0) return;

      this.$store.commit("setOrderId", null);
      this.$store.commit("setCheckedSellers", checkedSellers);
      this.$router.push({ name: "placeOrder" });
    },
    showQuantityDialog(variant) {
      this.quantityVariant = variant;
      this.quantity = variant.quantity;
      this.quantityShow = true;
    },
    calculateTotalPrice() {
      let totalPrice = 0;
      this.sellers.forEach((seller) => {
        const { products } = seller;

        products.forEach((product) => {
          const { variants } = product;

          let totalQuantity = 0;
          variants.forEach((v) => {
            const { quantity, checked } = v;
            if (checked) totalQuantity += quantity;
          });

          variants.forEach((variant) => {
            if (variant.checked) {
              const { quantity } = variant;
              const { price: discountPrice, priceRMB: discountPriceRMB } =
                this.getAppliedDiscountByProduct(variant, totalQuantity);
              variant.discountPrice = discountPrice;
              variant.discountPriceRMB = discountPriceRMB;

              const sum = this.NumberMul(discountPrice, quantity);
              totalPrice = this.NumberAdd(totalPrice, sum);
            } else {
              variant.discountPrice = 0;
              variant.discountPriceRMB = 0;
            }
          });
        });
      });
      this.totalPrice = parseFloat(totalPrice);
    },
    getAppliedDiscountByProduct(variant, totalQuantity) {
      const { quantity, price, metafields, cny_price } = variant;
      const { price_ranges } = metafields;

      if (metafields.sku_price_type != "rangePrice") {
        return { price, priceRMB: cny_price };
      }

      const { local, rmb } = JSON.parse(price_ranges);
      let priceRangeTHB = local.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });

      let priceRangeRMB = rmb.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });
      let disountPriceRMB;
      let disountPrice = (function (prices, quantity) {
        for (let index = 0; index < prices.length; index++) {
          const { startAmount, price } = prices[index];
          const { price: rmbPrice } = priceRangeRMB[index];
          if (index >= prices.length - 1) {
            disountPriceRMB = rmbPrice;
            return price;
          } else {
            const { startAmount: s } = prices[index + 1];
            if (quantity >= startAmount && quantity < s) {
              disountPriceRMB = rmbPrice;
              return price;
            }
          }
        }
      })(priceRangeTHB, totalQuantity ? totalQuantity : quantity);

      return {
        price: disountPrice,
        priceRMB: disountPriceRMB,
      };
    },
    queryCart() {
      const products = queryCarts();
      const sellers = [];

      products.forEach((product) => {
        const { seller_member_id, variants } = product;

        variants.forEach((v) => {
          const checked = this.store.checkedVariants[v.variantId];
          v.checked = checked;
        });

        const isAllChecked = variants.every((v) => {
          return v.checked;
        });
        product.checked = isAllChecked;

        const existSeller = sellers.find((seller) => {
          return seller.seller_member_id === seller_member_id;
        });

        if (existSeller) {
          const products = existSeller.products;
          products.push(product);
          existSeller.products = products;
        } else {
          const seller = {
            seller_member_id,
            products: [product],
          };
          sellers.push(seller);
        }
      });

      sellers.forEach((s) => {
        const sellerAllChecked = s.products.every((p) => {
          return p.checked;
        });
        s.checked = sellerAllChecked;
      });
      this.sellers = sellers;

      this.isAllSellerChecked();
    },
    removeCart(variant) {
      Dialog.confirm({
        title: "",
        message: this.$t("确定从购物车移除吗？"),
        cancelButtonText: this.$t("取消"),
        confirmButtonText: this.$t("确定"),
      })
        .then(() => {
          // on confirm
          removeCart(variant);
          this.queryCart();
        })
        .catch(() => {
          // on cancel
        });
    },
    async cartLinesRemove(lineId) {
      removeCart(lineId);
      Toast.success(this.$t("商品已从购物车移除"));
      this.queryCart();
    },
    onQuantity() {
      updateQuantity(this.quantityVariant, this.quantity);
      this.queryCart();
    },
    async cartLinesUpdate() {
      const { variantId } = this.updateLine;
      // updateCartCount(variantId, this.quantity);
      // this.queryCart();
    },
  },
  activated() {
    this.queryCart();
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;

.bottom-bar {
  position: fixed;
  bottom: 50px;
}

.cart-item {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  background-color: white;
}

.cart-item-content {
  flex: 1;
  padding-left: 10px;
}

.cart-item-price {
  display: inline-block;
  padding: 0px 5px;
}

.cart-item-bottom {
  color: red;
  display: flex;
  align-items: center;
}

.sku {
  color: #999;
  padding: 5px 0px;
}

.quantity {
  flex: 1;
  text-align: end;
  color: #333;
  font-size: large;
}

.cart-item-action {
  margin-top: 10px;
  text-align: right;
}

.cart-action-remove {
  display: inline-flex;
  align-items: center;
  padding: 10px 0px 0px;
  color: #666;
}

:deep(.van-stepper) {
  text-align: center;
  padding: 15px;
}

.vendor-item {
  background-color: white;
  border-radius: 10px;
  margin: 10px;
}

.vendor-title {
  padding: 10px 15px;
  color: #333;
  display: flex;
  align-items: center;
}

.vendor-name {
  display: inline-block;
  padding: 0px 5px;
}

.product-image {
  margin: 0px 10px;
}

.product-title {
  flex: 1;
}

.variant-item {
  padding: 0px 15px;
  display: flex;
  align-items: center;
}

.variant-sku {
  background-color: #f9f9f9;
  border-radius: 5px;
  display: inline-block;
  padding: 5px;
}

.color-thb {
  display: inline-block;
  margin-right: 5px;
  color: red;
  padding-left: 26px;
}

.pay-now {
  position: fixed;
  height: 45px;
  left: 0px;
  right: 0px;
  bottom: 50px;
  background-color: white;
  display: flex;
  align-items: center;
}

:deep(.van-button--block) {
  width: 180px;
  display: inline-block;
}

.pay-total {
  color: @primaryColor;
}

.van-button--small {
  padding: 0px 15px;
}

.product-title {
  flex: 1;
}
</style>