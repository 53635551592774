<template>
  <div>
    <van-pull-refresh
      v-if="isLogin()"
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')"
      :loading-text="$t('加载中...')"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        style="
                margin-bottom: 50px;height:100%'
            "
        :finished-text="histories.length ? $t('没有更多了') : ''"
        @load="onLoad"
      >
        <div
          class="history-item"
          v-for="history in histories"
          :key="history.id"
          @click="toSearchDetail(history)"
        >
          <van-image
            width="100%"
            height="180"
            fit="cover"
            :src="history.thumbnailUrl"
          />
          <div class="history-content">
            <div class="history-title">{{ history.title }}</div>
            <div>{{ history.text }}</div>
          </div>
        </div>
        <van-empty
          v-if="!histories.length"
          image="search"
          :description="$t('暂无数据')"
        />
      </van-list>
    </van-pull-refresh>

    <van-empty :description="$t('未登录')" v-if="!isLogin()">
      <van-button
        round
        type="danger"
        style="width: 100px"
        class="bottom-button"
        @click="toLogin()"
        >{{ $t("前往登录") }}</van-button
      >
    </van-empty>
  </div>
</template>

<script>
import { Toast, List, PullRefresh, Empty, Image, Button } from "vant";
import { searchHistory } from "./service";
import { parseUrl } from "@/utils/utils";
import { isLogin } from "@/utils/token";
import { toAuthorizationLogin } from "@/utils/pages";

export default {
  components: {
    [Empty.name]: Empty,
    [Image.name]: Image,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Button.name]: Button,
  },
  data() {
    return {
      histories: [],
      refreshing: false,
      currentPage: 1,
      pageSize: 10,
      pageInfo: null,
      finished: true,
      loading: false,
    };
  },
  methods: {
    toLogin() {
      Toast(this.$t("Please login"));
      setTimeout(() => {
        toAuthorizationLogin({
          name: "Home",
          query: {
            tabName: "search-history",
          },
        });
      }, 500);
    },
    onLoad() {
      this.loading = true;
      this.currentPage += 1;
      this.getData();
    },
    onRefresh() {
      this.currentPage = 1;
      this.refreshing = true;
      this.getData();
    },
    isLogin() {
      return isLogin();
    },
    toSearchDetail(history) {
      const { url, searchType } = history;
      const { queryMap } = parseUrl(url);
      delete queryMap.redirect;
      switch (searchType) {
        case "product_list":
          this.$router.push({
            name: "shopeeSearch",
            query: queryMap,
          });
          break;
        case "product_detail":
          this.$router.push({
            name: "Product",
            query: queryMap,
          });
          break;
      }
    },
    async getData() {
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
      };
      const { data, success, message, total } = await searchHistory(params);
      if (success) {
        if (this.currentPage === 1) this.histories = [];
        this.histories = this.histories.concat(data);

        this.finished = this.histories.length >= total;
      } else Toast.fail(message);

      this.refreshing = false;
      this.loading = false;
    },
  },
  mounted() {
    if (isLogin()) this.getData();
  },
};
</script>

<style lang="less" scoped>
.history-item {
  border-radius: 10px;
  border: solid 0.5px #dedede;
  margin: 10px;
  background-color: white;
}

.history-title {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  margin: 5px 0px;
  font-size: 1rem;
  color: #333;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.history-content {
  padding: 0px 15px 10px 15px;
  color: #999;
}

:deep(.van-image__img) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>